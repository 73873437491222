import type { ReactElement } from 'react';
import { LayoutFull } from '@uxu/design-system/components/layout/layoutFull';
import { LoadingDots } from '@uxu/design-system/components/atoms/loading';
import { SectionDashboard } from '../section';
import { DashboardHeader } from '../dashboardHeader';
import { Note } from '@uxu/design-system/components/atoms/note';
import { SectionMenu } from '../../molecules/sectionMenu';
import { footerConfig, headerMenuConfig } from '../../../config';
import { MessageFallbackProps } from './types';

const MESSAGE_MAP: Record<MessageFallbackProps['typ'], { message: string; action?: ReactElement; type: 'error' | 'warning' }> = {
  error: { message: 'Wystąpił błąd', type: 'error' },
  loading: { message: 'Ładowanie danych', action: <LoadingDots size={0.8} />, type: 'warning' },
  isNotAuth: { message: 'Brak dostępu, zaloguj się', type: 'error' },
  isNotAccess: { message: 'Brak uprawnień', type: 'warning' },
};

export function MessageFallback({ title, description, typ }: MessageFallbackProps): ReactElement {
  const { message, action, type } = MESSAGE_MAP[typ] || { message: 'Nieznany błąd', type: 'error' };

  return (
    <LayoutFull footer={footerConfig} headerMenu={headerMenuConfig}>
      <SectionDashboard>
        <SectionMenu data={[]} />
        <DashboardHeader title={title} description={description} />
        <Note action={action} style={{ width: '100%', margin: 'var(--uxu-space-default) 0' }} typ={type}>
          {message}
        </Note>
      </SectionDashboard>
    </LayoutFull>
  );
}
