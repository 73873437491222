import axios, {type AxiosResponse} from "axios";
import type {Response, FileData} from "@uxu/utils/common/types";


export type FilesGetProps = {page: number, limit: number}


export async function filesGet(props: FilesGetProps): Promise<Response<Array<FileData>>> {
    const {page, limit} = props;
    const res: AxiosResponse<Response<Array<FileData>>> = await axios.get('/api/v1/cdn', {
        params: {
            page,
            limit,
        }
    });

    return res.data;
}
