import {type ReactElement, useState, useEffect} from "react";
import {ArrowLeft} from 'react-feather';
import {useRouter} from "next/router";
import {Link} from "@uxu/design-system/components/atoms/link";
import styles from './styles.module.scss';
import type {SectionMenuProps} from "./types";

export function SectionMenu({data, returnToUrl}: SectionMenuProps): ReactElement | null {
    const router = useRouter();
    const [hasHistory, setHasHistory] = useState(false);

    useEffect(() => {
        setHasHistory(window.history.length > 1);
    }, []);

    function handleBackClick(): void  {
        if (returnToUrl) {
            void router.push(returnToUrl);
        } else {
            router.back();
        }
    }

    if (!data?.length && !hasHistory) {
        return null;
    }

    return (
        <div className={styles.container}>
            <ul className={styles.wrapper}>
                {hasHistory ? (
                    <li>
                        <button onClick={handleBackClick}
                                title="wstecz" type="button">
                            <ArrowLeft/>
                        </button>
                    </li>
                ) : null}
                {data?.map((link, index) => {
                    const isActive = router.pathname.startsWith(link.href);
                    const linkClass = isActive ? styles.active : "";

                    return (
                        <li key={index}>
                            <Link
                                className={linkClass}
                                href={link.href}
                                title={link.title}
                            >
                                <link.icon/>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}