import type { ReactElement } from "react";
import type { SectionEditDataProps, SectionEditDataOption } from "./types";
import styles from "./styles.module.scss";

export function SectionGridForInput({
    title,
    isRequire,
    description,
    manual,
    children,
    options,
    widget
}: SectionEditDataProps): ReactElement {
    function renderOptions(option: SectionEditDataOption): ReactElement | null {
        switch (option.type) {
            case "delete":
                return (
                    <button
                        className="btn error"
                        key={option.type}
                        onClick={option.onClick}
                        type="button"
                    >
                        usuń
                    </button>
                );
            case "save":
                return (
                    <button
                        className="btn primary"
                        key={option.type}
                        onClick={option.onClick}
                        type="button"
                    >
                        zapisz zmiany
                    </button>
                );
            case "add":
                return (
                    <button
                        className="btn primary"
                        key={option.type}
                        onClick={option.onClick}
                        type="button"
                    >
                        dodaj
                    </button>
                );
            case "change":
                return (
                    <button
                        className="btn primary"
                        key={option.type}
                        onClick={option.onClick}
                        type="button"
                    >
                        zmień
                    </button>
                );
            default:
                return null;
        }
    }


    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <header className={styles.header}>
                    <div className={styles.left}>
                        {title ? <strong>{title}{isRequire ? <span>*</span> : ""}</strong> : null}
                        {description ? <p>{description}</p> : null}
                    </div>
                    {widget ? <div className={styles.right}>{widget}</div> : null}
                </header>

                {children ? <div className={styles.wrapperInputs}>
                    {children}
                </div> : null}

                <div className={styles.options}>
                    {manual ? <div className={styles.manual}>{manual}</div> : null}
                    {options?.map((option) => renderOptions(option))}
                </div>
            </div>
        </section>
    )
}