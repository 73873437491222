import axios from "axios";
import type { AuthorData, TagData, CategoryData, PostData, Response } from "@uxu/utils/common/types";
import { CodeResponse } from "@uxu/utils/common/enums";

export async function blogAuthorAdd({ authorsData }: { authorsData: Array<AuthorData> }): Promise<Response<Array<AuthorData>>> {
    const API_URL = "/api/v1/blog/author";
    const response = await axios.post<Response<Array<AuthorData>>>(API_URL, authorsData);
    return response.data;
}

export async function blogAuthorUpdate({ authorData }: { authorData: AuthorData }): Promise<Response<AuthorData>> {
    const API_URL = "/api/v1/blog/author";
    const response = await axios.put<Response<AuthorData>>(API_URL, authorData);
    return response.data;
}

export async function blogAuthorDelete({ id }: { id: string }): Promise<Response> {
    const API_URL = `/api/v1/blog/author/${id}`;
    const response = await axios.delete<Response>(API_URL);
    return response.data;
}


export async function blogTagAdd({ tagsData }: { tagsData: Array<TagData> }): Promise<Response<Array<TagData>>> {
    const API_URL = "/api/v1/blog/tag";
    const response = await axios.post<Response<Array<TagData>>>(API_URL, tagsData);
    return response.data;
}

export async function blogTagUpdate({ tagData }: { tagData: TagData }): Promise<Response<TagData>> {
    const API_URL = "/api/v1/blog/tag";
    const response = await axios.put<Response<TagData>>(API_URL, tagData);
    return response.data;
}

export async function blogTagDelete({ id }: { id: string }): Promise<Response> {
    const API_URL = `/api/v1/blog/tag?id=${id}`;
    const response = await axios.delete<Response>(API_URL);
    return response.data;
}

export async function blogCategoryAdd({ categriesData }: { categriesData: Array<CategoryData> }): Promise<Response<Array<CategoryData>>> {
    const API_URL = "/api/v1/blog/category";
    const response = await axios.post<Response<Array<CategoryData>>>(API_URL, categriesData);
    return response.data;
}

export async function blogCategoryUpdate({ categoryData }: { categoryData: CategoryData }): Promise<Response<CategoryData>> {
    const API_URL = "/api/v1/blog/category";
    const response = await axios.put<Response<CategoryData>>(API_URL, categoryData);
    return response.data;
}

export async function blogCategoryDelete({ id }: { id: string }): Promise<Response> {
    const API_URL = `/api/v1/blog/category?id=${id}`;
    const response = await axios.delete<Response>(API_URL);
    return response.data;
}


export async function blogPostAdd({ postsData }: { postsData: Array<PostData> }): Promise<Response<Array<PostData>>> {
    const API_URL = "/api/v1/blog/post";
    const response = await axios.post<Response<Array<PostData>>>(API_URL, postsData);
    return response.data;
}

export async function blogPostUpdate({ postData }: { postData: PostData }): Promise<Response<PostData>> {
    const API_URL = "/api/v1/blog/post";
    const response = await axios.put<Response<PostData>>(API_URL, postData);
    return response.data;
}

export async function blogPostDelete({ id }: { id: string }): Promise<Response> {
    const API_URL = `/api/v1/blog/post?id=${id}`;
    const response = await axios.delete<Response>(API_URL);
    return response.data;
}

