import type {ReactElement} from "react";
import {useState} from "react";
import Image from "next/image";
import {File} from 'react-feather';
import {useCopyToClipboard} from "@uxu/design-system/hooks/useCopyToClipboard";
import {InfoModal} from "@uxu/design-system/components/organisms/infoModal";
import {Note} from "@uxu/design-system/components/atoms/note";
import type {Status} from "@uxu/utils/common/types";
import type {OnDeleteFileProps, SectionListFilesProps} from "./types";
import {CdnFileDelete} from "../../../../utils";
import styles from "./styles.module.scss";

// Function to check if the file format is an image
function isImageFormat(format: string): boolean {
    const supportedImageFormats = ["png", "jpg", "jpeg", "gif", "webp"];
    return supportedImageFormats.includes(format.toLowerCase());
}

export function SectionListFiles({data, onDelete, children}: SectionListFilesProps): ReactElement {
    const [copyStatus, copyToClipboard] = useCopyToClipboard();
    const [status, setStatus] = useState<OnDeleteFileProps>({
        fileName: null,
    });
    const [statusDelete, setStatusDelete] = useState<Status>("default");

    function handleModalToggle(props: OnDeleteFileProps): void {
       setStatus(props);
    }

    async function handleDeleteFile(): Promise<void> {
        if (status.fileName) {
            try {
                const response = await CdnFileDelete({
                    file_name: status.fileName
                });

                if (response.code === 200) {
                    onDelete(status);
                    setStatusDelete("success")
                    setTimeout(() => {
                        setStatusDelete("default")
                    }, 4000)
                }
            } catch (error) {
                setStatusDelete("error")
                setTimeout(() => {
                    setStatusDelete("default")
                }, 4000)
            }
        }
    }

    return (
        <>
            <InfoModal
                isOpen={status.fileName !== null}
                onClose={() => {
                    handleModalToggle({fileName: null});
                }}
                onDelete={() => {
                    if (status.fileName) {
                        void handleDeleteFile();
                        handleModalToggle({fileName: null});
                    }
                }}
            >
                <p>Czy na pewno chcesz usunąć {status.fileName}?</p>
            </InfoModal>
            <section className={styles.section}>
                {statusDelete === "success" && <Note typ="success">Plik został pomyślnie usunięty.</Note>}
                {statusDelete === "error" &&
                    <Note typ="error">Ups... Wystąpił błąd. Spróbuj usunąć plik ponownie.</Note>}
                <div className={styles.containerChildren}>
                    {children}
                </div>
                <div className={styles.container}>
                    {data.map((file) => {
                        return (
                            <div className={styles.file} key={file.metadata.name}>
                                <div className={styles.cover}>
                                    {isImageFormat(file.metadata.format) ? (
                                        <Image
                                            alt={file.metadata.name || ''}
                                            fill
                                            objectFit="cover"
                                            src={file.url}
                                        />
                                    ) : (
                                        <File/>
                                    )}
                                </div>
                                <div className={styles.data}>
                                    <p>{file.metadata.name}</p>
                                    <span>{file.metadata.format}</span>
                                    <button
                                        className={`btn error ${styles.button}`}
                                        onClick={() => {
                                            handleModalToggle({ fileName: file.metadata.name });
                                        }}
                                        type="button"
                                    >
                                        Usuń
                                    </button>
                                    <button
                                        className={`btn ${styles.button}`}
                                        onClick={() => {
                                            copyToClipboard(file.url);
                                        }}
                                        type="button"
                                    >
                                        {copyStatus === file.url ? "skopiowano" : "skopiuj link"}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
        </>
    );
}