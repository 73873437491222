import axios, { type AxiosResponse } from "axios";
import type { CodeCountry, Response, PostData, TagData, CategoryData, AuthorData } from "@uxu/utils/common/types";

/* POST */
export type BlogPostsGetProps = {
    page: number;
    limit: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    title?: string;
    client_id?: string;
    lang: string;
    published_at: boolean;
    deleted_at: boolean
}

export async function blogPostsGet(props: BlogPostsGetProps): Promise<Response<Array<PostData>>> {
    const { page, limit, sortBy, sortOrder, title, client_id, lang, published_at, deleted_at } = props;

    const params: Record<string, unknown> = {
        page,
        limit,
        sort_by: sortBy,
        sort_order: sortOrder,
        lang,
        published_at,
        deleted_at,
    };

    if (title?.length) {
        params.title = title;
    }
    if (client_id?.length) {
        params.client_id = client_id;
    }

    const res: AxiosResponse<Response<Array<PostData>>> = await axios.get('/api/v1/blog/post', {
        params
    });

    return res.data;
}

export type BlogPostGetBySlugProps = {
    slug: string;
    client_id: string;
}

export async function blogPostGetBySlug(props: BlogPostGetBySlugProps): Promise<Response<PostData>> {
    const { slug, client_id } = props;
    const res: AxiosResponse<Response<PostData>> = await axios.get(`/api/v1/blog/post/${client_id}/${slug}`);
    return res.data;
}

/* TAG */

export type BlogTagsGetProps = {
    page: number;
    limit: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    name?: string;
    client_id?: string;
    lang: string;
    published_at: boolean;
    deleted_at: boolean
}


export async function blogTagsGet(props: BlogTagsGetProps): Promise<Response<Array<TagData>>> {
    const { page, limit, sortBy, sortOrder, name, client_id, lang, published_at, deleted_at } = props;

    console.log(props, "props");
    const params: Record<string, unknown> = {
        page,
        limit,
        sort_by: sortBy,
        sort_order: sortOrder,
        lang,
        published_at,
        deleted_at,
    };

    if (name?.length) {
        params.name = name;
    }
    if (client_id?.length) {
        params.client_id = client_id;
    }

    const res: AxiosResponse<Response<Array<TagData>>> = await axios.get('/api/v1/blog/tag', {
        params
    });

    return res.data;
}

export type BlogTagGetBySlugProps = {
    slug: string;
    client_id: string;
}

export async function blogTagGetBySlug(props: BlogTagGetBySlugProps): Promise<Response<TagData>> {
    const { slug, client_id } = props;
    const res: AxiosResponse<Response<TagData>> = await axios.get(`/api/v1/blog/tag/${client_id}/${slug}`);
    return res.data;
}

/* CATEGORY */

export type BlogCategoriesGetProps = {
    page: number;
    limit: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    name?: string;
    client_id?: string;
    lang: string;
    published_at: boolean;
    deleted_at: boolean
}


export async function blogCategoriesGet(props: BlogCategoriesGetProps): Promise<Response<Array<CategoryData>>> {
    const { page, limit, sortBy, sortOrder, name, client_id, lang, published_at, deleted_at } = props;


    const params: Record<string, unknown> = {
        page,
        limit,
        sort_by: sortBy,
        sort_order: sortOrder,
        lang,
        published_at,
        deleted_at,
    };

    if (name?.length) {
        params.name = name;
    }
    if (client_id?.length) {
        params.client_id = client_id;
    }

    const res: AxiosResponse<Response<Array<CategoryData>>> = await axios.get('/api/v1/blog/category', {
        params
    });

    return res.data;
}

export type BlogCategoryGetBySlugProps = {
    slug: string;
    client_id: string;
}

export async function blogCategoryGetBySlug(props: BlogCategoryGetBySlugProps): Promise<Response<CategoryData>> {
    const { slug, client_id } = props;
    const res: AxiosResponse<Response<CategoryData>> = await axios.get(`/api/v1/blog/category/${client_id}/${slug}`);
    return res.data;
}


/* AUTHOR */

export type BlogAuthorsGetProps = {
    page: number;
    limit: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    name?: string;
    published_at: boolean;
    deleted_at: boolean
}


export async function blogAuthorsGet(props: BlogAuthorsGetProps): Promise<Response<Array<AuthorData>>> {
    const { page, limit, sortBy, sortOrder, name, deleted_at, published_at } = props;


    const params: Record<string, unknown> = {
        page,
        limit,
        sort_by: sortBy,
        sort_order: sortOrder,
        published_at,
        deleted_at,
    };

    if (name?.length) {
        params.name = name;
    }

    const res: AxiosResponse<Response<Array<AuthorData>>> = await axios.get('/api/v1/blog/author', {
        params
    });

    return res.data;
}

export type BlogAuthorGetBySlugProps = {
    slug: string;
}

export async function blogAuthorGetBySlug(props: BlogAuthorGetBySlugProps): Promise<Response<AuthorData>> {
    const { slug } = props;
    const res: AxiosResponse<Response<AuthorData>> = await axios.get(`/api/v1/blog/author/${slug}`);
    return res.data;
}