import axios from "axios";
import type { Response, FileData } from "@uxu/utils/common/types";

export interface CdnFileDeleteProps {
    file_name: string;
}

export const CdnFileDelete = async ({ file_name }: CdnFileDeleteProps): Promise<Response> => {
    const { data } = await axios.delete<Response>(`/api/v1/cdn`, {
        params: { file_name },
    });
    return data;
};

export interface CdnFileUploadProps {
    files: FileList;
    alt?: string[];
    title?: string[];
}

export const CdnFileUpload = async ({ files, alt, title }: CdnFileUploadProps): Promise<Response<Array<FileData>>> => {
    const formData = new FormData();
    
    Array.from(files).forEach((file, index) => {
        formData.append('files', file);
        formData.append('alt', alt?.[index] || '');
        formData.append('title', title?.[index] || '');
    });

    const { data } = await axios.post<Response<Array<FileData>>>('/api/v1/cdn', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return data;
};