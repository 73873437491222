import { type ReactElement, useCallback, useEffect, useState, useMemo } from 'react';
import { Home, Layers, Users, MessageCircle, Folder, Coffee, PhoneCall, Briefcase } from "react-feather";
import { Link } from "@uxu/design-system/components/atoms/link";
import { haveAccess } from '@uxu/utils/frontend/auth';
import { useSiteConfig } from "@uxu/design-system/hooks/useSiteConfig";
import { useRouter } from "next/router";
import { useAppSelector } from "@uxu/design-system/store";
import { readAccessRequirements } from "../../../../../utils";


export function RenderLinks(): ReactElement {
    const config = useSiteConfig();
    const router = useRouter();

    const isActive = useCallback((href: string): string => {
        return router.pathname.startsWith(href) ? "active" : '';
    }, [router.pathname]);

    return (
        <>
            <li>
                <Link href="/dashboard" title="start">
                    <Home />
                </Link>
            </li>
            {haveAccess('read', readAccessRequirements.blog).isAccess && <li>
                <Link className={isActive('/dashboard/blog')} href="/dashboard/blog/post" title="blogi">
                    <Coffee />
                </Link>
            </li>}
            {haveAccess('read', readAccessRequirements.cdn).isAccess && <li>
                <Link className={isActive('/dashboard/cdn')} href="/dashboard/cdn" title="pliki">
                    <Folder />
                </Link>
            </li>}
            {haveAccess('read', readAccessRequirements.telbook).isAccess && <li>
                <Link className={isActive('/dashboard/telbook')} href="/dashboard/telbook/phone" title="telefony">
                    <PhoneCall />
                </Link>
            </li>}
        </>
    );
}
