import type { ReactElement } from "react";
import { parseFormatDate } from "@uxu/utils/common/date";
import type { SectionGridForDataProps } from "./types";
import styles from "./styles.module.scss";
import { DummyImg } from "@uxu/design-system/components/atoms/dummyIMG";
import { generateImageLinks } from "@uxu/utils/common/other";

export function SectionGridForData({ children, data, seo }: SectionGridForDataProps): ReactElement {




    function formatDate(label: string, date: Date | null | undefined): ReactElement {
        return (
            <li>
                <p>
                    <strong>{label}:</strong>
                    <span> {date ? parseFormatDate(date) : "null"}</span>
                </p>
            </li>
        );
    }

    return (
        <section className={styles.section}>
            <div className={styles.gridContainer}>
                <div className={styles.childrenContainer}>
                    {children}
                </div>
                <div className={styles.infoRail}>
                    <div className={styles.infoContainer}>
                    {Boolean(seo) && <div className={styles.contentWrapper}>
                            <div className={styles.headerWrapper}>
                                <span>SEO</span>
                            </div>
                            <ul>
                                <li>
                                    <p>
                                        <strong>Tytuł:</strong>
                                        <span> {seo?.title?.length ? seo.title : "brak"}</span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Opis:</strong>
                                        <span> {seo?.description?.length ? seo.description : "brak"}</span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>slug:</strong>
                                        <span> {seo?.slug?.length ? seo.slug : "brak"}</span>
                                    </p>
                                </li>
                                <li style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "var(--uxu-space-small)" }}>
                                    <strong>Miniatura</strong>
                                    <div className={styles.coverWrapper}>
                                        {seo?.cover?.src ? <img src={generateImageLinks(seo.cover.src)?.xs} /> : <DummyImg width="2rem" height="2rem" />}
                                    </div>
                                </li>
                            </ul>
                        </div>}
                        <div className={styles.contentWrapper}>
                            <div className={styles.headerWrapper}>
                                <span>Informacje</span>
                            </div>
                            <ul>
                                {formatDate("Utworzono", data?.created_at)}
                                {formatDate("Opublikowano", data?.published_at)}
                                {formatDate("Ostatnia aktualizacja", data?.modified_at)}
                                {formatDate("Usunięto", data?.deleted_at)}
                                <li>
                                    <p>
                                        <strong>Utworzone przez (id):</strong>
                                        <span> {data?.owner_id}</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        {Boolean(data?.onDelete) && (
                            <button
                                className="btn error"
                                onClick={data?.onDelete}
                                type="button"
                            >
                                Usuń
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}